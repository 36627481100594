var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-main',[_c('v-container',[_c('v-row',[_c('v-col',[_c('h1',[_vm._v("Ders Kayıtları")])])],1),_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_c('v-text-field',{attrs:{"append-icon":"search","label":"Ara","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.refresh()}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("refresh")])],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.cregs,"search":_vm.search,"loading":_vm.loading,"loading-text":"Yükleniyor...","sort-by":['user.fullName', 'course.name'],"multi-sort":"","show-expand":"","single-expand":""},scopedSlots:_vm._u([{key:"item.user.fullName",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.user.fullName))]),_vm._v(" "),_c('br'),_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(item.user.email))]),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(item.user.id)+" ")]}},{key:"item.course.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.course.name)+" "),_c('br'),_vm._v(" "+_vm._s(item.course.id)+" ")]}},{key:"item.dateCreated",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.dateCreated.toLocaleString("tr-TR"))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"close-on-content-click":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("more_vert")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.openItem(item)}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("visibility")])],1),_c('v-list-item-title',[_vm._v("Göster")])],1)],1)],1)]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[(
                      item.completedLessons &&
                        item.completedLessons.length == 0
                    )?_c('p',{staticClass:"mt-4"},[_vm._v(" Tamamlanmış modül bulunamadı. ")]):_vm._e(),(item.completedLessons)?_c('ul',{staticClass:"mt-4"},_vm._l((item.completedLessons),function(cl){return _c('li',{key:cl.id},[_c('h3',[_vm._v("Modül "+_vm._s(cl.rowNumber)+": "+_vm._s(cl.title))]),_c('p',[_vm._v(_vm._s(cl.id))]),_c('p',[_vm._v(" İlerleme: "+_vm._s(cl.progress.toFixed(2))+" / Yer İmi: "+_vm._s(cl.bookmark)+" / Tamamlandı: "+_vm._s(cl.completed ? "Evet" : "Hayır")+" ")]),(cl.quizzes && cl.quizzes.length > 0)?_c('ul',{staticClass:"mb-4"},_vm._l((cl.quizzes),function(q){return _c('li',{key:q.id},[_vm._v(" "+_vm._s(q.dateCreated.toLocaleString("tr-TR"))+" tarihinde yapılan "+_vm._s(q.id)+" kodlu sınavda "),(q.status == 'passed')?_c('span',{staticClass:"success--text"},[_vm._v("geçti")]):_vm._e(),(q.status == 'failed')?_c('span',{staticClass:"error--text"},[_vm._v("kaldı")]):_vm._e()])}),0):_vm._e()])}),0):_vm._e()])]}}],null,true)})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }