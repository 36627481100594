























































































































import { Vue, Component } from "vue-property-decorator";
import firebase from "@/plugins/firebase";

interface Creg {
  id: string;
  user: User | null;
  course: Course | null;
  progress: number;
  dateCreated: Date;
  isActive: boolean;
  progressEbook: number;
  completedLessons: CompletedLesson[] | null | undefined;
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
}

interface Course {
  id: string;
  urlCode: string;
  name: string;
}

interface CompletedLesson {
  id: string;
  progress: number;
  bookmark: {
    h: string;
    v: string;
  };
  completed: boolean | null;
  title: string | null;
  rowNumber: string | null;
  quizzes: Quiz[] | null;
}

interface Quiz {
  id: string;
  status: "passed" | "failed";
  dateCreated: Date;
}

@Component({
  metaInfo: {
    title: "Ders Kayıtlarının Kontrolü ve Düzeltilmesi"
  }
})
export default class AdminTestCregs extends Vue {
  cregs = [] as Creg[];
  search = "";
  loading = false;
  headers = [
    {
      text: "Kullanıcı",
      align: "start",
      filterable: true,
      value: "user.fullName"
    },
    { text: "Ders", value: "course.name", filterable: true },
    { text: "Tamamlanma", value: "progress" },
    { text: "Tarih", value: "dateCreated" },
    { text: "Id", value: "id", filterable: true },
    { text: "", value: "actions", sortable: false, width: "50px" }
  ];

  async getCregs() {
    this.cregs = [];

    const cregQ = await firebase
      .firestore()
      .collection("courseRegistrations")
      .get();

    let i = 0;

    cregQ.forEach(async creg => {
      if (creg.exists) {
        // Kullanıcıyı al
        const userQ = await firebase
          .firestore()
          .collection("users")
          .where("uid", "==", creg.data().uid)
          .get();

        const userDoc = userQ.docs[0];

        // Dersi al
        const courseDoc = await firebase
          .firestore()
          .doc(`courses/${creg.data().courseId}`)
          .get();

        const newCreg = {
          id: creg.id,
          progress: creg.data().progress,
          dateCreated: creg.data().dateCreated.toDate(),
          isActive: creg.data().isActive,
          user: {
            id: userDoc.data()?.uid,
            firstName: userDoc.data()?.firstName,
            lastName: userDoc.data()?.lastName,
            fullName:
              userDoc.data()?.firstName + " " + userDoc.data()?.lastName,
            email: userDoc.data()?.email
          },
          course: {
            id: courseDoc.id,
            urlCode: courseDoc.data()?.urlCode,
            name: courseDoc.data()?.name
          },
          completedLessons: [] as CompletedLesson[]
        } as Creg;

        // Tamamlanmış modülleri al
        newCreg.completedLessons = creg.data()
          .completedLessons as CompletedLesson[];

        if (newCreg.completedLessons) {
          newCreg.completedLessons.forEach(async (cl, i) => {
            const lessonDoc = await firebase
              .firestore()
              .doc(`lessons/${cl.id}`)
              .get();

            if (lessonDoc.exists) {
              cl.title = lessonDoc.data()?.title;
              cl.rowNumber = lessonDoc.data()?.rowNumber;
            }

            // Yapılan sınavlar
            cl.quizzes = [] as Quiz[];

            const quizQ = await firebase
              .firestore()
              .collection("quizzes")
              .where("uid", "==", newCreg.user?.id)
              .where("courseId", "==", newCreg.course?.id)
              .where("lessonId", "==", cl.id)
              .orderBy("dateCreated")
              .get();

            quizQ.forEach(quizDoc => {
              const newQuiz = {
                id: quizDoc.id,
                status: quizDoc.data()?.status,
                dateCreated: quizDoc.data().dateCreated.toDate()
              } as Quiz;

              cl.quizzes?.push(newQuiz);

              // Eğer başarılı bir quiz var ise
              // Modülü tamamlandı olarak işaretle
              // if (newQuiz.status == "passed") {
              //   cl.completed = true;
              // }
            });

            if (newCreg.completedLessons) {
              newCreg.completedLessons[i] = cl;
            }
          });
        }

        this.cregs.push(newCreg);

        //console.log(newCreg);

        i++;
      }
    });
  }

  openItem(item: Creg) {
    console.log(item);
  }

  async refresh() {
    await this.getCregs();
  }

  async updateCreg(i: number) {
    const creg = this.cregs[i];

    console.log(creg);

    if (creg.completedLessons) {
      const mappedCl = creg.completedLessons.map(cl => {
        return {
          id: cl.id,
          progress: cl.progress,
          bookmark: cl.bookmark,
          completed: cl.completed
        };
      });

      console.log(mappedCl);
    }

    // // Tamamlanan modülleri baz alarak ilerlemeyi yeniden hesapla

    // // Toplam modül sayısını al
    // const lessonQs = await firebase
    //   .firestore()
    //   .collection("lessons")
    //   .where("courseId", "==", creg.course?.id)
    //   .get();

    // const lessonCount = lessonQs.docs.length;
    // let completedLessonCount = 0;

    // // Tamamlanan e-ders sayısını al ve e-dersi tamamlandı olarak ayarla
    // if (creg.completedLessons) {
    //   creg.completedLessons.forEach(cl => {
    //     if (cl.completed) {
    //       completedLessonCount = completedLessonCount + 1;
    //     }
    //   });
    // }

    // // Kullanıcının dersteki ilerlemesini güncelle
    // // İlerleme yüzdesini hesapla
    // let progress = (100 * completedLessonCount) / lessonCount;
    // progress = progress > 100 ? 100 : progress;

    // const cregRef = firebase.firestore().doc(`courseRegistrations/${creg.id}`);
    // await cregRef.update({
    //   progress: progress,
    //   completedLessons: completedLessons
    // });
  }

  async mounted() {
    await this.getCregs();
  }
}
